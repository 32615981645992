<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <!-- row -->
  <div class="container-fluid">
    <div class="row">

      <div class="col-xl-4 col-sm-6" *ngIf="isSuperadmin">
        <div class="card border coin-holding-card border-info">
          <div class="card-body bg-info rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Payment</h4>
                <span class="text-white fs-14 op7">By Month {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalpayments}}</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Bill</h4>
                <span class="text-white fs-14 op7">By Month {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalbill}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Inbound</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalinbh}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Inbound</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalinbb}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-warning">
          <div class="card-body bg-warning rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Inbound</h4>
                <span class="text-white fs-14 op7">Tahun {{datenow | date : 'y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalinbt}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Proses</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshpph}}</h4>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Shipment Proses</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppb}}</h4>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-xl-6 col-sm-12">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Paket Proses Pengiriman</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshpph1}} AWB</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-sm-12">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Paket Proses Pengiriman</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppb1}} AWB</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Paket Proses</h4>
                <span class="text-white fs-14 op7">Tanggal {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshawb}} AWB</h4>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Paket Proses</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshawbbulan}} AWB</h4>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Paket Proses</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshawb1}} AWB</h4>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize" style="
                font-size: 18px;
            ">Paket Proses Masuk</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshawb1}} AWB</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize" style="
                font-size: 18px;
            ">Paket Proses Masuk</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshawbbulan1}} AWB</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h6 class="font-500 text-white title mb-0 fontsize">Berat Paket Masuk</h6>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshawbkg}} KG</h4>
            </div>
          </div>
          <div class="card-body">
            <!-- <p>{{totalshawbkgpercen}} % Berat Paket Masuk</p> -->
            <p><ngb-progressbar type="warning" [value]="totalshawbkgpercen" [striped]="true" [animated]="true"><i>{{totalshawbkgpercen}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <div class="card border coin-holding-card border-danger">
          <div class="card-body bg-danger rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h6 class="font-500 text-white title mb-0 fontsize">Berat Paket Masuk</h6>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshawbkg1}} KG</h4>
                <!-- <p><ngb-progressbar type="warning" [value]="75"></ngb-progressbar></p> -->
            </div>
          </div>
          <div class="card-body">
            <p><ngb-progressbar type="dark" textType="white" [value]="totalshawbkg1percen" [striped]="true" [animated]="true"><i>{{totalshawbkg1percen}} %</i></ngb-progressbar></p>                  
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xl-6 col-xxl-4">
          <div class="card">
              <div class="card-header d-block">
                  <h4 class="card-title">Contextual progress bars</h4>
              </div>
              <div class="card-body">
                <p><ngb-progressbar type="warning" [value]="75" [striped]="true" [animated]="true"><i>50%</i></ngb-progressbar></p>                  
              </div>
          </div>
      </div>
    </div> -->
    <!-- <p><ngb-progressbar type="info" [value]="50">Copying file 2 of 4</ngb-progressbar></p>
<p><ngb-progressbar type="warning" [value]="75" [striped]="true" [animated]="true"><i>50%</i></ngb-progressbar></p>
<p><ngb-progressbar type="danger" [value]="100" [striped]="true">Completed!</ngb-progressbar></p>
    <p><ngb-progressbar type="dark" [value]="totalshawbkg1percen">{{totalshawbkg1percen}} %</ngb-progressbar></p>
    <p><ngb-progressbar [value]="434" type="dark"></ngb-progressbar></p>
    <p><ngb-progressbar type="warning" textType="white" [value]="100" [showValue]="true"></ngb-progressbar></p> -->
    <!-- <p><ngb-progressbar [value]="{{totalshawbkg1}}" type="dark"></ngb-progressbar></p> -->

    <!-- <div class="row">
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Hari ini {{datenow | date : 'd MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshpph1}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Bulan {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppb1}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-4">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/3.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Shipment Selesai</h4>
                <span class="text-white fs-14 op7">Tahun {{datenow | date : 'y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalshppt1}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div> -->


      <div class="row">
      <div class="col-xl-6 col-xxl-6" *ngIf="isSuperadmin">
        <app-graph-market-shipper></app-graph-market-shipper>
      </div>
      
      <div class="col-xl-6 col-xxl-6" *ngIf="isSuperadmin">
        <app-graph-market-country></app-graph-market-country>
      </div>

      <div class="col-xl-6 col-xxl-12" *ngIf="isSuperadmin">
        <app-graph-market-periode></app-graph-market-periode>
      </div>

      <!-- <div class="col-xl-6 col-xxl-12" *ngIf="isSuperadmin || isAdmin">
        <app-graph-market-user></app-graph-market-user>
      </div> -->

      <!-- <div class="col-xl-12">
        <div class="card">
            <div class="card-header pb-0">
                <div class="mt-3 mt-sm-0">
                    
                    
                    <ul class="nav nav-tabs" role="tablist" ngbNav #nav="ngbNav" >
                        <li class="nav-item" ngbNavItem="navpills-1">
                            <a class="nav-link" data-toggle="tab" href="#navpills-1" role="tab" aria-selected="false" ngbNavLink>
                                BILL SHIPPERS	
                            </a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Shipper</th>
                                                    <th scope="col">Jan</th>
                                                    <th scope="col">Feb</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Thu Oct 17 2019 11:03:53</td>
                                                    <td>BTC</td>
                                                    <td>BTC</td>
                                                    <td>BTC</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem="navpills-2">
                            <a class="nav-link" data-toggle="tab" href="#navpills-2" role="tab" aria-selected="false" ngbNavLink>
                                PAYMENT VENDOR
                            </a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Vendor</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Wed Oct 16 2019 09:56:03</td>
                                                <td class="">14374</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem="navpills-3">
                            <a class="nav-link" data-toggle="tab" href="#navpills-3" role="tab" aria-selected="false" ngbNavLink>
                                STOP-LIMIT
                            </a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive">
                                
                                    <table class="table table-bordered ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Time</th>
                                                <th scope="col">Offer Id</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Stop-limit Price</th>
                                                <th scope="col">Trigger Price</th>
                                                <th scope="col">Buy/Sell</th>
                                                <th scope="col">Edit</th>
                                                <th scope="col">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aug 28, 2019, 3:49:55 PM</td>
                                                <td>566</td>
                                                <td>1</td>
                                                <td>80</td>
                                                <td>90</td>
                                                <td class="text-danger">Sell</td>
                                                <td>
                                                    <button class="btn btn-info btn-sm">Modify</button>
                                                </td>
                                                <td>
                                                    <button class="btn btn-danger btn-sm">Delete</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-body ">
                <div [ngbNavOutlet]="nav" class=""></div>
            </div>
        </div>
    </div>  -->

      <!-- <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-success">
          <div class="card-body bg-success rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/4.svg" class="mr-3" />
              <div>
                <h4 class="font-500 text-white title mb-0 fontsize">Total Shipment</h4>
                <span class="text-white fs-14 op7">By Month {{datenow | date : 'MMM y'}}</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-right rightharga">
                <h4 class="font-w500 text-white amount mb-0">{{totalbill}}</h4>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-secondary">
          <div class="card-body bg-secondary rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/1.svg" class="mr-3" />
              <div>
                <h4 class="font-w500 text-white title mb-0">Total Ton</h4>
                <span class="text-white fs-14 op7">By Month Year 2022</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-center">
              <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white" />
                  <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)"
                    fill="white" />
                  <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)"
                    fill="white" />
                  <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)"
                    fill="white" />
                </svg>
                <h4 class="font-w500 text-white amount mb-0">$168,331.09</h4>
              </div>
              <div class="mb-2">
                <img src="assets/images/svg/chevron_up.svg" class="mt-1 mr-1" />
                <span class="text-white fs-14">45% This Week</span>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between border-0">
            <div class="footer-info font-w600">
              <span class="text-success pr-2">BUY</span>
              <span class="text-black">$5,673</span>
            </div>
            <div class="footer-info font-w600">
              <span class="text-danger pr-2">SELL</span>
              <span class="text-black">$5,982</span>
            </div>
            <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More
              Details</a>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-secondary">
          <div class="card-body bg-secondary rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/1.svg" class="mr-3" />
              <div>
                <h4 class="font-w500 text-white title mb-0">Total New Customer</h4>
                <span class="text-white fs-14 op7">By Month Year 2022</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-center">
              <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white" />
                  <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)"
                    fill="white" />
                  <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)"
                    fill="white" />
                  <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)"
                    fill="white" />
                </svg>
                <h4 class="font-w500 text-white amount mb-0">$168,331.09</h4>
              </div>
              <div class="mb-2">
                <img src="assets/images/svg/chevron_up.svg" class="mt-1 mr-1" />
                <span class="text-white fs-14">45% This Week</span>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between border-0">
            <div class="footer-info font-w600">
              <span class="text-success pr-2">BUY</span>
              <span class="text-black">$5,673</span>
            </div>
            <div class="footer-info font-w600">
              <span class="text-danger pr-2">SELL</span>
              <span class="text-black">$5,982</span>
            </div>
            <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More
              Details</a>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-secondary">
          <div class="card-body bg-secondary rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/1.svg" class="mr-3" />
              <div>
                <h4 class="font-w500 text-white title mb-0">UNCOMPETE PAYMENT</h4>
                <span class="text-white fs-14 op7">By Month Year 2022</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-center">
              <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white" />
                  <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)"
                    fill="white" />
                  <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)"
                    fill="white" />
                  <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)"
                    fill="white" />
                </svg>
                <h4 class="font-w500 text-white amount mb-0">$168,331.09</h4>
              </div>
              <div class="mb-2">
                <img src="assets/images/svg/chevron_up.svg" class="mt-1 mr-1" />
                <span class="text-white fs-14">45% This Week</span>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between border-0">
            <div class="footer-info font-w600">
              <span class="text-success pr-2">BUY</span>
              <span class="text-black">$5,673</span>
            </div>
            <div class="footer-info font-w600">
              <span class="text-danger pr-2">SELL</span>
              <span class="text-black">$5,982</span>
            </div>
            <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More
              Details</a>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-xl-4 col-sm-6">
        <div class="card border coin-holding-card border-secondary">
          <div class="card-body bg-secondary rounded">
            <div class="d-flex align-items-center">
              <img src="assets/images/coin/coin_holding/1.svg" class="mr-3" />
              <div>
                <h4 class="font-w500 text-white title mb-0">COMPLETE PAYMENT</h4>
                <span class="text-white fs-14 op7">By Month Year 2022</span>
              </div>
            </div>
            <div class="d-flex flex-wrap mt-4 align-items-center">
              <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white" />
                  <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)"
                    fill="white" />
                  <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)"
                    fill="white" />
                  <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)"
                    fill="white" />
                </svg>
                <h4 class="font-w500 text-white amount mb-0">$168,331.09</h4>
              </div>
              <div class="mb-2">
                <img src="assets/images/svg/chevron_up.svg" class="mt-1 mr-1" />
                <span class="text-white fs-14">45% This Week</span>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between border-0">
            <div class="footer-info font-w600">
              <span class="text-success pr-2">BUY</span>
              <span class="text-black">$5,673</span>
            </div>
            <div class="footer-info font-w600">
              <span class="text-danger pr-2">SELL</span>
              <span class="text-black">$5,982</span>
            </div>
            <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More
              Details</a>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</div>
      <!-- <div class="col-lg-4" *ngFor="let coins of coinHolding">
            <div class="card border coin-holding-card {{coins.border_class}}">
                <div class="card-body {{coins.bg_class}} rounded">
                    <div class="d-flex align-items-center">
                        <img src="{{coins.image}}" class="mr-3"/>
                        <div>
                            <h4 class="font-w500 text-white title mb-0">{{coins.name}}</h4>
                            <span class="text-white fs-14 op7">{{coins.short_name}}</span>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap mt-4 align-items-center">
                        <div class="d-flex align-items-center mr-auto pr-3 mb-2">
                            <svg class="mr-3" width="42" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="3.42856" height="25.1428" rx="1.71428" transform="matrix(-1 0 0 1 24 0)" fill="white"/>
                                <rect width="3.42856" height="18.2856" rx="1.71428" transform="matrix(-1 0 0 1 17.1431 6.85712)" fill="white"/>
                                <rect width="3.42856" height="7.99997" rx="1.71428" transform="matrix(-1 0 0 1 10.2861 17.1428)" fill="white"/>
                                <rect width="3.86812" height="15.4725" rx="1.93406" transform="matrix(-1 0 0 1 3.86816 9.67029)" fill="white"/>
                            </svg>
                            <h4 class="font-w500 text-white amount mb-0">{{coins.price}}</h4>
                        </div>
                        <div class="mb-2">
                            <img src="{{coins.up_down_image}}" class="mt-1 mr-1"/>
                            <span class="text-white fs-14">{{coins.up_down}}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-between border-0">
                    <div class="footer-info font-w600">
                        <span class="text-success pr-2">BUY</span>
                        <span class="text-black">{{coins.buy}}</span>
                    </div>
                    <div class="footer-info font-w600">
                        <span class="text-danger pr-2">SELL</span>
                        <span class="text-black">{{coins.sell}}</span>
                    </div>
                    <a [routerLink]="['/admin/coin-details']" class="underline text-primary font-w500 footer-info">More Details</a>
                </div>
            </div>
        </div> -->
      <!-- <div class="col-xl-3 col-sm-6">
                <app-graph1></app-graph1>
            </div>
            <div class="col-xl-3 col-sm-6">
                <app-graph2></app-graph2>
            </div>
            <div class="col-xl-3 col-sm-6">
                <app-graph3></app-graph3>
            </div>
            <div class="col-xl-3 col-sm-6">
                <app-graph4></app-graph4>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <app-graph-market-overview></app-graph-market-overview>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <app-graph-crypto-statistics></app-graph-crypto-statistics>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="row">
                    <div class="col-xl-12">
                        <app-quick-transfer [data]="quickTransfer"></app-quick-transfer>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-xxl-12">
                <div class="row">
                    <div class="col-sm-6">
                        <app-sell-order></app-sell-order>
                    </div>
                    <div class="col-sm-6">
                        <app-buy-order></app-buy-order>
                    </div>
                </div>
            </div> -->

<!--**********************************
    Content body end
***********************************-->
