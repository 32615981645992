<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
      <!-- <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Reports</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Inbound</a></li>
                  </ol>
              </div>
          </div>
      </div> -->

      <div class="row page-titles" style="margin-bottom: 0px;">
        <div class="col-sm-6 p-md-0 ">
          <div class="welcome-text">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Reports</a></li>
                <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Inbound</a></li>
            </ol>
          </div>
        </div>
        <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
            <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa la-search scale5 mr-2"></i>Form Filter</a>
            <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        </div>
      </div>

   
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List Inbound</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Code</th>
                    <th scope="col">Paket</th>
                    <th scope="col">Resi</th>
                    <th scope="col">Kurir</th>
                    <th scope="col">Tanggal Kirim</th>
                    <th scope="col">Tanggal Terima</th>
                    <th scope="col">Pengirim</th>
                    <th scope="col">Tanggal Input</th>
                    <th scope="col">Shippers</th>
                    <th scope="col">CreateBy</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <th scope="row">
                      {{ x + 1 }}
                    </th>
                    <td>
                      {{ tutorial.code }}
                    </td>
                    <td *ngIf="tutorial.status_kurir == 'Y'">
                      Kurir
                    </td>
                    <td *ngIf="tutorial.status_pickup == 'Y'">
                      Pickup
                    </td>
                    <td *ngIf="tutorial.status_direct == 'Y'">
                      Direct
                    </td>
                    <td *ngIf="tutorial.no_resi != 'null'">
                      {{ tutorial.no_resi }}
                    </td>
                    <td *ngIf="tutorial.no_resi == 'null'">
                      -
                    </td>
                    <td>{{ tutorial.namakurirs }}</td>
                    <!-- <td>{{ tutorial.status_kurir }}</td> -->
                    <td *ngIf="tutorial.status_kurir == 'Y'">{{ tutorial.tgl_kirim }}</td>
                    <td *ngIf="tutorial.status_kurir == 'N'">-</td>
                    <td *ngIf="tutorial.status_paket == 'Y'">{{ tutorial.tgl_terima }}</td>
                    <td *ngIf="tutorial.status_paket == 'N'">-</td>
                    <!-- <td>{{ tutorial.nameshippers }}</td> -->
                    <!-- <td *ngIf="tutorial.status_kurir == 'Y'">
                      {{ tutorial.nameshippers }}
                    </td>
                    <td *ngIf="tutorial.status_pickup == 'Y'">
                      {{ tutorial.name_pengirim }}
                    </td> -->
                    <td>
                      {{ tutorial.name_pengirim }}
                    </td>
                    <td>{{ tutorial.tglinput }}</td>
                    
                    <td>{{ tutorial.nameshippers }}</td>
                    <td>{{ tutorial.full_name }}</td>

                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination class="pagination-responsive"
                [collectionSize]="count"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
              >
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select
                  class="mr-sm-2 custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="handlePageSizeChange($event)"
                >
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Filter</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<!-- <form class="comment-form"> -->
			<div class="row">
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Start Date</label>
            <input
              type="date"
              class="form-control"
              id="tglinput_mulai"
              [(ngModel)]="tglinput_mulai"
              #name="ngModel"
              name="tglinput_mulai"
            />
          </div>
				</div>
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">End Date</label>
            <input
              type="date"
              class="form-control"
              id="tglinput_selesai"
              [(ngModel)]="tglinput_selesai"
              #name="ngModel"
              name="tglinput_selesai"
            />
          </div>
				</div>
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Code</label>
            <input
              type="text"
              class="form-control"
              id="code"
              [(ngModel)]="code"
              #name="ngModel"
              name="code"
            />
          </div>
				</div>
        <div class="col-lg-4 mb-3">
          <label class="text-black font-w600">Shipper</label>
            <select class="form-control default-select" *ngIf="vcombos.length > 0" id="shipper_id" [(ngModel)]="shipper_id" #name="ngModel" name="shipper_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombos" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>

				<div class="col-lg-12">
					<div class="form-group mb-0">
            <button (click)="searchTitle()" class="submit btn btn-primary mb-3 mr-3">Search</button>
            <button (click)="downloadExcel()" class="submit btn btn-danger mb-3 mr-3"><i class="fa fa-file-excel-o mr-2"></i> Export Excel</button>
					</div>
				</div>
			</div>
		<!-- </form> -->
	</div>
</ng-template>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
