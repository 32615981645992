import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'ngbd-modal-confirm',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Profile deletion</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="OnSubmit('no')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
    <span class="text-danger">This operation can not be undone.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="OnSubmit('no')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="OnSubmit('Ok')">Ok</button>
  </div>
  `
})
export class NgbdModalConfirm {
  @Input() public data: any;
  constructor(public modal: NgbActiveModal) {
    // console.log(this.data)
  }

  OnSubmit(status:any) {
    this.modal.close(status);
  }
}

export interface Tutorial {
  id?: any;
  code?: string,
  tglinput?: string,
  tglinput_mulai?: string,
  tglinput_selesai?: string,
  // reff_name?: string,
  // phone_number?: string,
  // id_country?: number,
  // country?: string,
  // id_city?: number,
  // city?: string,
  // post_code?: number,
  // address?: string,
  // arc_number?: string,
  // is_aktif?: string,
  // is_delete?: string,
  // connote?: string,
  // ship_date?: string,
  // code_resi_inbound?: string;
  // airwaybill?: string,
  nameshippers?: string,
  // nameconsignee?: string,
  // namevendor?: string,
  // shipper_id?: string,
  // vendor_id?: string,
  tgl_kirim?: string,
  tgl_terima?: string,
  status_kurir?: string,
  status_pickup?: string,
  status_direct?: string,
  no_resi?: string,
  status_paket?: string,
  name_pengirim?: string,
  namakurirs?: string,
  full_name?: string,
  // published?: boolean;
  action?: string
}

const MODALS: {[name: string]: Type<any>} = {
  focusFirst: NgbdModalConfirm
};

@Component({
  selector: 'app-rp-inbound',
  templateUrl: './rp-inbound.component.html',
  styleUrls: ['./rp-inbound.component.css']
})
export class RpInboundComponent implements OnInit {


  tutorials: Tutorial[] = [];
  tutorialsTemp: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';
  shipper_id = '';
  tglinput = '';
  tglinput_mulai = '';
  tglinput_selesai = '';
  vendor_id = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 500, 1000, 10000];
  isCreated:boolean = true;

  // status_pickup: any;
  // status_direct: any;

  vcombos:any = [];
  vcomboss:any = [];

  closeResult = '';
  modal: any;
  ExcelData: any;
  file: any;
  exceljsondata: any;
  arrayBuffer: any;
  code: any;

  constructor(private modalService: NgbModal, private tutorialService: MasterService, public toastService: ToastService, private router: Router) { }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    this.shipper_id = '';
    this.code = '';
    // this.ship_date = '';
    // this.vendor_id = '';
		this.modalService.open(content, { size: 'lg' });

	}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.retrieveTutorials();
    this.retrieveCombodatashippers();
    // this.retrieveCombodatavendors();
  }



  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    // console.log(params[`shipper_id`], 'params')

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    // console.log(this.shipper_id)
    params.shipper_id = this.shipper_id;
    // params.tglinput = this.tglinput;
    params.tglinput_mulai = this.tglinput_mulai;
    params.tglinput_selesai = this.tglinput_selesai;
    params.code = this.code;
    // console.log(params)

    this.tutorialService.getAlls('inbound/listview', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodatashippers(): void {
    this.page = 0
    this.pageSize = 100
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    // const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('shippers/viewshipper', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombos = data;
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }


  initializeItems(): void {
    this.tutorials = this.tutorialsTemp;
  }



  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    // console.log('tes close searchTitle form search');
    // this.modal.close();
    this.modalService.dismissAll();
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    code: '',
    // ship_date: '',
  };


  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

  async downloadExcel() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.shipper_id = this.shipper_id;
    // params.tglinput = this.tglinput;
    params.tglinput_mulai = this.tglinput_mulai;
    params.tglinput_selesai = this.tglinput_selesai;
    params.code = this.code;
    // console.log(params, 'params')

    this.tutorialService.postData('inbound/downloadexcel', params)
      .subscribe(
        async response => {

        var anchor = document.createElement('a');
        anchor.href = 'https://apiexpl.hallo.or.id/uploads/files/inbound/'+response.message;
        // anchor.href = 'http://localhost:39998/uploads/files/inbound/'+response.message;
        // anchor.href = 'http://localhost:7777/uploads/files/inbound/'+response.message;
        anchor.target="_blank";
        anchor.click();

        this.presentToast('Generate Excel Inbound Success', 'success');
          this.modalService.dismissAll();
          this.retrieveTutorials();
        },
        async error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });
  }

}
