<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <!-- <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Transaction</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Outbound</a></li>
                  </ol>
              </div>
          </div>
      </div> -->

      <div class="d-flex align-items-center flex-wrap mb-3">
        <!-- <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex"> -->
          <!-- <input
            type="text"
            class="form-control"
            placeholder="Search by title"
            [(ngModel)]="title"
          />btn light btn-info
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div> -->
        <!-- </div> -->
        <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="openfilter(sendMessageModalfilter)"><i class="fa la-search scale5 mr-2"></i>Form Filter & Download Excel</a>
        <a href="javascript:void(0);" class="btn btn-info btn-rounded mb-3 mr-3" (click)="opensetstatustracking(sendMessageModaltracking)" *ngIf="isSuperadmin"><i class="fa fa-book scale5 mr-2"></i>Set Tracking Status</a>
        <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)" *ngIf="isSuperadmin"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <!-- <button class="btn btn-primary btn-rounded mb-3 mr-3" (click)="delete()">Set Tracking Status</button> -->
    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List Outbound</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="">
                        <!-- <div class="custom-control custom-checkbox mx-2">
                          <input type="checkbox" class="custom-control-input" id="checkAll" (change)="checkUncheckAll($event)">
                          <label class="custom-control-label" for="checkAll"></label>
                        </div> -->
                        <input type="checkbox" name="all" [checked]="isAllChecked()" (change)="checkAll($event)"/>
                      </th>
                      <th scope="col">No</th>
                      <th scope="col">Invoice</th>
                      <th scope="col">Date</th>                      
                      <th scope="col" *ngIf="isSuperadmin || isAdmin">MAWB</th>
                      <th scope="col" *ngIf="isSuperadmin || isAdmin">Flight Number</th>
                      <th scope="col" *ngIf="isSuperadmin">Vendor</th>
                      <th scope="col">Bag Code</th>
                      <th scope="col">Note</th>
                      <!-- <th scope="col">Status</th> -->
                      <!-- <th scope="col">Password</th> -->
                      <!-- <th scope="col">Level</th> -->
                      <th scope="col" *ngIf="isSuperadmin">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <td>
                          <input type="checkbox" name="tutorialcb[]" value="{{tutorial.id}}" [(ngModel)]="tutorial.state"
                          (click)="check(tutorial)"/>
                          <!-- <div class="custom-control custom-checkbox mx-2">
                            <input type="checkbox" class="custom-control-input" id="checkbox{{tutorial.id}}">
                            <label class="custom-control-label" for="checkbox{{tutorial.id}}"></label>
                          </div> -->
                        </td>
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <td>{{ tutorial.invoice_no }}</td>
                        <td>{{ tutorial.manifest_date }}</td>
                        <td *ngIf="isSuperadmin || isAdmin">{{ tutorial.mawb }}</td>
                        <td *ngIf="isSuperadmin || isAdmin">{{ tutorial.flight_number }}</td>
                        <td *ngIf="isSuperadmin">{{ tutorial.namevendors }}</td>
                        <td>{{ tutorial.bag_code }}</td>
                        <td>{{ tutorial.note }}</td>
                        <!-- <td>{{ tutorial.status }}</td> -->
                        <td *ngIf="isSuperadmin">
                          <!-- <a [routerLink]="['/admin/app-tr-manifest']" class="btn btn-rounded btn-outline-primary mt-sm-4 mt-2"><i class="fa fa-box scale5 mr-3"></i></a> -->
                          <!-- <a href="#" [routerLink]="['/post/', post.id, 'edit']" class="btn btn-primary">Edit</a> -->
                          <a href="#" [routerLink]="['/admin/app-tr-manifest-bag/', tutorial.id ]" class="btn btn-primary btn-xxs mr-2 mb-2" placement="left" ngbTooltip="Manifest Bag" *ngIf="isSuperadmin || isAdmin">
                            <i class="fa fa-archive color-info"></i></a>
                          <!-- <a href="#" [routerLink]="['/admin/app-tr-manifest-bag']" class="btn btn-outline btn-xs btn-dark"><i class="fa fa-archive color-info"></i></a>&nbsp; -->
                          <button type="button" class="btn btn-info btn-xxs mr-2 mb-2" (click)="editUser(sendMessageModal, tutorial, 'update')" placement="left" ngbTooltip="Edit">
                            <i class="fa fa-pencil color-info"></i>
                            </button>
                          <button type="button" class="btn btn-danger btn-xxs mr-2 mb-2" (click)="deleteUser(tutorial)" placement="left" ngbTooltip="Delete">
                            <i class="fa fa-trash color-info"></i>
                            </button>
                          <!-- <a href="#" [routerLink]="['/admin/app-tr-manifest-bag']" class="btn btn-danger btn-xxs mr-2 mb-2" placement="left" ngbTooltip="Delete">
                              <i class="fa fa-trash color-info"></i></a> -->
                          <!-- <button type="button" class="btn btn-rounded btn-danger"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                          </span>Remove</button> -->
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>

              <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination class="pagination-responsive"
                  [collectionSize]="count"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                >
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select
                    class="mr-sm-2 custom-select"
                    style="width: auto"
                    name="pageSize"
                    (change)="handlePageSizeChange($event)"
                  >
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>
              </div>

            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Outbound</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">

        <div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">Vendor <span class="required">*</span></label>
            <select class="form-control default-select" *ngIf="vcombo.length > 0" id="vendor_id" [(ngModel)]="fadd_role.vendor_id" #name="ngModel" name="vendor_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombo" value="{{n.id}}">{{n.name}}</option>
            </select>
          </div>
				</div>

				<!-- <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Invoice <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="invoice_no"
              required
              [(ngModel)]="fadd_role.invoice_no"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="invoice_no"
            />
          </div>
				</div> -->

				<div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">Manifest Date <span class="required">*</span></label>
            <input
              type="date"
              class="form-control"
              id="manifest_date"
              required
              [(ngModel)]="fadd_role.manifest_date"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="manifest_date"
            />
          </div>
				</div>
				<div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">MAWB <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="mawb"
              required
              [(ngModel)]="fadd_role.mawb"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="mawb"
            />
          </div>
				</div>
				<div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">Flight Number <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="flight_number"
              required
              [(ngModel)]="fadd_role.flight_number"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="flight_number"
            />
          </div>
				</div>
				<div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">Keberangkatan <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="keberangkatan"
              required
              [(ngModel)]="fadd_role.keberangkatan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="keberangkatan"
            />
          </div>
				</div>
				<div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">Kedatangan <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="kedatangan"
              required
              [(ngModel)]="fadd_role.kedatangan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="kedatangan"
            />
          </div>
				</div>
				<div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">Tanggal Keberangkatan <span class="required">*</span></label>
            <input
              type="date"
              class="form-control"
              id="tgl_keberangkatan"
              required
              [(ngModel)]="fadd_role.tgl_keberangkatan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="tgl_keberangkatan"
            />
          </div>
				</div>
				<div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">Tanggal Kedatangan <span class="required">*</span></label>
            <input
              type="date"
              class="form-control"
              id="tgl_kedatangan"
              required
              [(ngModel)]="fadd_role.tgl_kedatangan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="tgl_kedatangan"
            />
          </div>
				</div>
				<div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">Jam Keberangkatan <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="jam_keberangkatan"
              required
              [(ngModel)]="fadd_role.jam_keberangkatan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="jam_keberangkatan"
              placeholder="00:00:00"
            />
          </div>
				</div>
				<div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">Jam Kedatangan <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="jam_kedatangan"
              required
              [(ngModel)]="fadd_role.jam_kedatangan"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="jam_kedatangan"
              placeholder="00:00:00"
            />
          </div>
				</div>

        <div class="col-lg-8" *ngIf="isSuperadmin || isAdmin">
          <div class="form-group">
            <label class="text-black font-w600"
              >Status Transit <span class="required">*</span></label
            >
            <select (change)="onChange($event.target)"
            [ngModelOptions]="{standalone: true}"
            placeholder="Select Status Transit"
            class="form-control default-select" id="isStatusTransit" [(ngModel)]="fadd_role.isStatusTransit" #name="ngModel" name="isStatusTransit">
              <option ng-value="Tidak">Tidak</option>
              <option ng-value="Ya">Ya</option>
            </select>
            </div>
        </div>
        <div class="col-lg-6" *ngIf="statusopen">
          <div class="form-group">
            <label class="text-black font-w600"
              >Keberangkatan Transit </label>
              <input
                type="text"
                class="form-control"
                id="keberangkatan_t"
                [(ngModel)]="fadd_role.keberangkatan_t"
                #name="ngModel"
                [disabled]="fadd_role.action == 'view'"
                name="keberangkatan_t"
              />
            </div>
        </div>
        <div class="col-lg-6" *ngIf="statusopen">
					<div class="form-group">
						<label class="text-black font-w600">Kedatangan Transit</label>
            <input
              type="text"
              class="form-control"
              id="kedatangan_t"
              [(ngModel)]="fadd_role.kedatangan_t"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="kedatangan_t"
            />
          </div>
				</div>
        <div class="col-lg-6" *ngIf="statusopen">
          <div class="form-group">
            <label class="text-black font-w600"
              >Tanggal Keberangkatan Transit </label>
              <input
                type="date"
                class="form-control"
                id="tgl_keberangkatan_t"
                [(ngModel)]="fadd_role.tgl_keberangkatan_t"
                #name="ngModel"
                [disabled]="fadd_role.action == 'view'"
                name="tgl_keberangkatan_t"
              />
            </div>
        </div>
        <div class="col-lg-6" *ngIf="statusopen">
					<div class="form-group">
						<label class="text-black font-w600">Tanggal Kedatangan Transit</label>
            <input
              type="date"
              class="form-control"
              id="tgl_kedatangan_t"
              [(ngModel)]="fadd_role.tgl_kedatangan_t"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="tgl_kedatangan_t"
            />
          </div>
				</div>
        <div class="col-lg-6" *ngIf="statusopen">
					<div class="form-group">
						<label class="text-black font-w600">Jam Keberangkatan Transit</label>
            <input
              type="text"
              class="form-control"
              id="time_keberangkatan_t"
              [(ngModel)]="fadd_role.jam_keberangkatan_t"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="time_keberangkatan_t"
              placeholder="00:00"
            />
          </div>
				</div>
        <div class="col-lg-6" *ngIf="statusopen">
					<div class="form-group">
						<label class="text-black font-w600">Jam Kedatangan Transit</label>
            <input
              type="text"
              class="form-control"
              id="time_kedatangan_t"
              [(ngModel)]="fadd_role.jam_kedatangan_t"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="time_kedatangan_t"
              placeholder="00:00"
            />
          </div>
				</div>

				<!-- <div class="col-lg-12" *ngIf="isSuperadmin">
					<div class="form-group">
						<label class="text-black font-w600">Flights <span class="required">*</span></label>
            <select class="form-control default-select" *ngIf="vcombosflight.length > 0" id="flight_id" [(ngModel)]="fadd_role.flight_id" #name="ngModel" name="flight_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombosflight" value="{{n.id}}">{{n.code}}</option>
            </select>
          </div>
				</div> -->
        <div class="col-lg-6" *ngIf="isSuperadmin || isAdmin">
					<div class="form-group">
						<label class="text-black font-w600">set first bag code <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="bag_code"
              required
              [(ngModel)]="fadd_role.bag_code"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="bag_code"
              placeholder="set first bag code"
            />
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Note <span class="required">*</span></label>
            <textarea class="form-control" class="form-control"
            required
            [(ngModel)]="fadd_role.note"
            #name="ngModel"
            [disabled]="fadd_role.action == 'view'"
            name="note"></textarea>
          </div>
				</div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<!-- SET STATUS TRACKING -->
<ng-template #sendMessageModaltracking let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Set Status Tracking</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
    <ngb-alert [dismissible]="false" type="danger">
      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="mr-2"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
      <strong>This tracking status will be applicable to all cleansing inside the selected manifest</strong>
    </ngb-alert>

		<form class="comment-form">
			<div class="row">
        <div class="col-lg-12 mb-3">
          <label class="text-black font-w600">Pilih Status <span class="required">*</span></label>
              <select class="form-control default-select" id="status" [(ngModel)]="status" #name="ngModel" name="status">
                <option ng-value="">Please select</option>
                <option ng-value="MANIFESTED">MANIFESTED</option>
                <option ng-value="PROCESS TO AIRPORT">PROCESS TO AIRPORT</option>
                <option ng-value="CLEREANCE AT ORIGIN">CLEREANCE AT ORIGIN</option>
                <option ng-value="TRANSIT AT ORIGIN">TRANSIT AT ORIGIN</option>
                <option ng-value="ARRIVED AT DESTINATION">ARRIVED AT DESTINATION</option>
                <option ng-value="TRANSIT AT DESTINATION">TRANSIT AT DESTINATION</option>
                <option ng-value="OUT FOR DELIVERY">OUT FOR DELIVERY</option>
                <option ng-value="TROUBLE">TROUBLE</option>
                <option ng-value="DELIVERED">DELIVERED</option>
              </select>
        </div>

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="setStatus()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<!-- FILTER -->
<!-- Modal ADD -->
<ng-template #sendMessageModalfilter let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Filter</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<!-- <form class="comment-form"> -->
			<div class="row">
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Manifest Date</label>
            <input
              type="date"
              class="form-control"
              id="manifest_date"
              [(ngModel)]="manifest_date"
              #name="ngModel"
              name="manifest_date"
            />
          </div>
				</div>

        <div class="col-lg-4 mb-3" *ngIf="isSuperadmin">
          <label class="text-black font-w600">Vendor</label>
            <select class="form-control default-select" *ngIf="vcomboss.length > 0" id="vendor_id" [(ngModel)]="vendor_id" #name="ngModel" name="vendor_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcomboss" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>

				<div class="col-lg-12" *ngIf="(loading$ | async)">
					<div class="form-group mb-0">
              <div >
                <span class="indicator-progress" [style.display]="'block'">
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </div>
          </div>
				</div>
				<div class="col-lg-12" *ngIf="!(loading$ | async)">
					<div class="form-group mb-0">
            <button (click)="searchTitle()" class="submit btn btn-primary mb-3 mr-3">Search</button>
            <button (click)="downloadExcel()" class="submit btn btn-danger mb-3 mr-3"><i class="fa fa-file-excel-o mr-2"></i> Export Excel</button>
            <button (click)="syncroneStatus()" class="submit btn btn-warning mb-3 mr-3" *ngIf="isSuperadmin"><i class="fa fa-refresh mr-2"></i> Sync Status</button>
            <button (click)="syncroneStatus1()" class="submit btn btn-warning mb-3 mr-3" *ngIf="isSuperadmin"><i class="fa fa-refresh mr-2"></i> Sync Status 1</button>
          </div>
				</div>

			</div>
		<!-- </form> -->
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
