// import {
//   Component, ElementRef, EventEmitter, OnInit, ViewChild,
//   // AfterViewInit,
//   // Component,
//   // ElementRef,
//   // OnDestroy,
//   // OnInit,
//   Renderer2,
//   // ViewChild,
//   // Output,
//   // Input,
//   // EventEmitter
//  } from '@angular/core';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
// import { Select2OptionData } from "ng-select2";
// import { Select2OptionData } from 'ng2-select2';
// import { Options } from "select2";
// import { Select2Module } from "ng-select2-component";
// import {Html5QrcodeScanner} from "html5-qrcode";
// import { Html5QrcodeScanner } from 'html5-qrcode';
// import {Html5Qrcode} from "html5-qrcode";
// import {Html5QrcodeCameraScanConfig} from "html5-qrcode/esm/html5-qrcode";
// import {Html5QrcodeResult} from "html5-qrcode/esm/core";

import { MasterService } from '../../../_services/master.service';

import { ActivatedRoute, Router } from '@angular/router';


import { ZXingScannerComponent } from '@zxing/ngx-scanner';

import { Result, BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';

// export type ChartOptions = {
//   series: ApexNonAxisChartSeries | any;
//   chart: ApexChart | any;
//   responsive: ApexResponsive[] | any;
//   labels: any;
//   dataLabels: ApexDataLabels | any;
//   stroke: ApexStroke | any;
//   colors: string[] | any;
//   legend: ApexLegend | any;
// };
// export interface Country {
//   idm: number;
//   name: string;
//   // flag: string;
//   // area: number;
//   // population: number;
// }
//type data


export interface Tutorial {
  id?: any;
  manifest_bag_id?: string,
  cleansing_id?: string,
  connote?: string,
  airwaybill?: string,
  ship_date?: string,
  date_bag_details?: string,
  bag_no?: string,
  label?: string,
  action?: string;
}

@Component({
  selector: 'app-tr-manifest-bag-detail',
  templateUrl: './tr-manifest-bag-detail.component.html',
  styleUrls: ['./tr-manifest-bag-detail.component.css']
})
export class TrManifestbagdetailComponent implements OnInit {
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';
  // idbag = this.route.snapshot.params['postId']

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated:boolean = true;
  // vcombo: any;
  vcombo:any = [];
  id: any;
  idmanifest:any;

  //scan
  availableDevices!: MediaDeviceInfo[];
  currentDevice!: MediaDeviceInfo;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices!: boolean;
  hasPermission!: boolean;

  qrResultString!: string;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private ChangeDetectorRef:ChangeDetectorRef
    ) { }

  ngOnInit(): void {

    this.idmanifest = this.route.snapshot.paramMap.get('idmanifest');
    // console.log(this.route.snapshot.paramMap.get('idmanifest'), 'idmanifest');
    // console.log(this.route.snapshot.paramMap.get('idbag'), 'idbag');
    // this.id = this.route.snapshot.params['postId'];
    // console.log(this.id, 'thisid');
    this.retrieveTutorials();
    this.retrieveCombodata();


  }



  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    // console.log(this.qrResultString)
    this.fadd_role.cleansing_id = resultString;
    // console.log('test')
    // this.saveTutorial();
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }


  open(content:any): void {
    this.isCreated = true;
    this.fadd_role.action = 'create';
		this.modalService.open(content);
	}

  opens(contents: any) {
    this.modalService.open(contents);
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {


    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.id = this.route.snapshot.params['idbag'];
    params.manifest_bag_id = this.id
    // console.log(this.id, 'thisid');
    // console.log(params)

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('manifests_bags_detail/view', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodata(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('vendors/view', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombo = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    manifest_bag_id:this.route.snapshot.params['idbag'],
    cleansing_id:'',
    // label:'',
    // flight_number:'',
    // vendor_id:'',
    // note:'',
    // status:'',
    // description: '',
    // published: false
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      manifest_bag_id: this.route.snapshot.params['idbag'],
      cleansing_id: this.fadd_role.cleansing_id
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      this.tutorialService.postData('manifests_bags_detail/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
            // this.reloadPage();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('manifests_bags_detail/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
            // this.reloadPage();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.manifest_bag_id = tutorial.manifest_bag_id;
    this.fadd_role.cleansing_id = tutorial.cleansing_id;
    // this.fadd_role.label = tutorial.label;
    // this.fadd_role.flight_number = tutorial.flight_number;
    // this.fadd_role.vendor_id = tutorial.vendor_id;
    // this.fadd_role.note = tutorial.note;
    // this.fadd_role.status = tutorial.status;
    this.fadd_role.action = action;
  }

  deleteUser(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`manifests_bags_detail/delete/${data.id}`)
        .subscribe(
          async response => {
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  // reloadPage(): void {
  //   // window.location.reload();
  //   // this.token.signOut();
	// 	// window.location.reload();
	// 	this.router.navigate(['admin/app-tr-manifest-bag/', this.fadd_role.manifest_id]);
  //   // this.router.navigate(["admin/index"]);
  // }
  resultScan(result:any) {
    console.log(result)
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 6000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 6000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
