<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <!-- <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Consignee</a></li>
                  </ol>
              </div>
          </div>
      </div> -->

      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <input
            type="text"
            class="form-control"
            placeholder="Search by title"
            [(ngModel)]="title"
          />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div>
        </div>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List Consignee</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Refference Name</th>
                      <th scope="col">Phone Code</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">ACC Number</th>
                      <th scope="col">Country</th>
                      <th scope="col">City</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <td>
                          {{ tutorial.name }}
                        </td>
                        <td>
                          {{ tutorial.reff_name }}
                        </td>
                        <td>
                          {{ tutorial.phonecode }}
                        </td>
                        <td>
                          {{ tutorial.phone_number }}
                        </td>
                        <td>
                          {{ tutorial.arc_number }}
                        </td>
                        <td>
                          {{ tutorial.namecountry }}
                        </td>
                        <td>
                          {{ tutorial.namecity }}
                        </td>
                        <td>
                          <button type="button" class="btn btn-rounded btn-info" (click)="editUser(sendMessageModal, tutorial, 'update')"><span
                            class="btn-icon-left text-info"><i class="fa fa-pencil color-info"></i>
                            </span>Edit</button>&nbsp;
                          <button type="button" class="btn btn-rounded btn-danger" (click)="deleteConsignee(tutorial)"><span
                              class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                              </span>Remove</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>

               <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination class="pagination-responsive"
                  [collectionSize]="count"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                >
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select
                    class="mr-sm-2 custom-select"
                    style="width: auto"
                    name="pageSize"
                    (change)="handlePageSizeChange($event)"
                  >
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>
              </div>

            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Consignee</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Name <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="name"
              required
              [(ngModel)]="fadd_role.name"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="name"
            />
          </div>
				</div>

				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Reference Name <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="reff_name"
              required
              [(ngModel)]="fadd_role.reff_name"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="reff_name"
            />
          </div>
				</div>

        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-label">Phone Number</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend" ngbDropdown>
                <button
                  class="btn btn-primary dropdown-toggle d-flex justify-content-between"
                  type="button"
                  [disabled]="action == 'view'"
                  data-toggle="dropdown"
                  ngbDropdownToggle
                >
                  <img
                    [src]="
                      'http://' +
                      selectedShipperPhoneNumberCountry?.flag
                    "
                    *ngIf="selectedShipperPhoneNumberCountry?.flag"
                    (error)="
                      selectedShipperPhoneNumberCountry.flag =
                        '/assets/images/qr.png'
                    "
                    width="30"
                    class="mr-2"
                  />
                  <span *ngIf="selectedShipperPhoneNumberCountry?.phonecode">+{{
                    selectedShipperPhoneNumberCountry?.phonecode
                  }}</span>
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <p-dropdown
                  [options]="countries"
                  optionLabel="name"
                  [filter]="true"
                  filterBy="name"
                  [disabled]="action == 'view'"
                  [showClear]="true"
                  [(ngModel)]="selectedShipperPhoneNumberCountry"
                  [(ngModel)]="fadd_role.phonecode"
                  [ngModelOptions]="{standalone: true}"
                  styleClass="w-100"
                  placeholder="Select a Phonecode"
                  (onChange)="onDropdownChange($event, 'phonecode')"
                  (onClear)="onDropdownClear('phonecode')"
                >
                  <ng-template pTemplate="selectedItem">
                    <div class="" *ngIf="selectedShipperPhoneNumberCountry">
                      <div>+{{ selectedShipperPhoneNumberCountry.phonecode }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="">
                      <div>+{{item.phonecode}} {{ item.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
                </div>
              </div>
              <input
              type="text"
              class="form-control"
              id="phone_number"
              required
              [(ngModel)]="fadd_role.phone_number"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="phone_number"
            />
              <!-- <input
                type="text"
                class="form-control"
                [(ngModel)]="fadd_role.phone_number"
              /> -->
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label class="form-label w-100"
              >Country <span class="text-danger">*</span></label
            >

            <p-dropdown
              [options]="typeItems"
              optionLabel="name"
              [filter]="true"
              [disabled]="action == 'view'"
              filterBy="name"
              [showClear]="true"
              styleClass="w-100"
              [(ngModel)]="selectedTypeItem"
              [ngModelOptions]="{standalone: true}"
              placeholder="Select a Type"
              (onChange)="onDropdownChange($event, 'type_id')"
              (onClear)="onDropdownClear('type_id')"
            >
              <ng-template pTemplate="selectedItem">
                <div class="" *ngIf="selectedTypeItem">
                  <div>{{ selectedTypeItem.name }}</div>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="">
                  <div>{{ item.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="form-label w-100"
              >City <span class="text-danger">*</span></label
            >

            <p-dropdown
              [options]="subtypeItems"
              optionLabel="name"
              [filter]="true"
              filterBy="name"
              [disabled]="action == 'view'"
              [showClear]="true"
              styleClass="w-100"
              [(ngModel)]="selectedSubTypeItem"
              [ngModelOptions]="{standalone: true}"
              placeholder="Select a Type"
              (onChange)="onDropdownChange($event, 'subtype_id')"
              (onClear)="onDropdownClear('subtype_id')"
            >
              <ng-template pTemplate="selectedItem">
                <div class="" *ngIf="selectedSubTypeItem">
                  <div>{{ selectedSubTypeItem.name }}</div>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="">
                  <div>{{ item.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Address <span class="required">*</span></label>
            <textarea class="form-control" class="form-control"
            required
            [(ngModel)]="fadd_role.address"
            #name="ngModel"
            [disabled]="fadd_role.action == 'view'"
            name="Address"></textarea>
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Post Code <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="post_code"
              required
              [(ngModel)]="fadd_role.post_code"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="post_code"
            />
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">ARC Number <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="arc_number"
              required
              [(ngModel)]="fadd_role.arc_number"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="arc_number"
            />
          </div>
				</div>


				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
