<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
      <!-- <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Reports</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Cleansings</a></li>
                  </ol>
              </div>
          </div>
      </div>

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
      </div>
      <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa la-search scale5 mr-2"></i>Form Filter</a>
      <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
    </div> -->

    <div class="row page-titles" style="margin-bottom: 0px;">
      <div class="col-sm-6 p-md-0 ">
        <div class="welcome-text">
          <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="javascript:void(0)">Reports</a></li>
              <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Cleansings</a></li>
          </ol>
      </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa la-search scale5 mr-2"></i>Form Filter</a>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
     </div>
    </div>
    <!-- row -->
    <div class="row">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List Cleansing</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Ship Date</th>
                    <th scope="col">Connote</th>
                    <th scope="col">Airwaybill</th>
                    <th scope="col">Awb Vendor</th>
                    <!-- <th scope="col">Code resi inbound</th> -->
                    <th scope="col">Shipper</th>
                    <th scope="col">Consignee</th>
                    <th scope="col">Vendor</th>
                    <th scope="col">CreateBy</th>
                    <!-- <th scope="col">Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <th scope="row">
                      {{ x + 1 }}
                    </th>
                    <td>
                      {{ tutorial.ship_date }}
                    </td>
                    <td>
                      {{ tutorial.connote }}
                    </td>
                    <td>
                      {{ tutorial.airwaybill }}
                    </td>
                    <td>
                      {{ tutorial.awb_vendor }}
                    </td>
                    <td>
                      {{ tutorial.nameshippers }}
                    </td>
                    <td>
                      {{ tutorial.nameconsignee }}
                    </td>
                    <td>
                      {{ tutorial.namevendor }}
                    </td>
                    <td>
                      {{ tutorial.full_name }}
                    </td>
                    <!-- <td> -->
                      <!-- <button
                        type="button"
                        class="btn btn-rounded btn-dark"
                        (click)="openPrint(tutorial, 'view')"
                      >
                        <span class="btn-icon-left text-dark"
                          ><i class="fa fa-print color-dark"></i> </span
                        >Print</button
                      >&nbsp; -->
                    <!-- </td> -->
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination class="pagination-responsive"
                [collectionSize]="count"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
              >
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select
                  class="mr-sm-2 custom-select"
                  style="width: auto"
                  name="pageSize"
                  (change)="handlePageSizeChange($event)"
                >
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Filter</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<!-- <form class="comment-form"> -->
			<div class="row">
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">Start Ship Date</label>
            <input
              type="date"
              class="form-control"
              id="start_ship_date"
              [(ngModel)]="start_ship_date"
              #name="ngModel"
              name="start_ship_date"
            />
          </div>
				</div>
				<div class="col-lg-4">
					<div class="form-group">
						<label class="text-black font-w600">End Ship Date</label>
            <input
              type="date"
              class="form-control"
              id="end_ship_date"
              [(ngModel)]="end_ship_date"
              #name="ngModel"
              name="end_ship_date"
            />
          </div>
				</div>
        <div class="col-lg-4 mb-3">
          <label class="text-black font-w600">Shipper</label>
            <select class="form-control default-select" *ngIf="vcombos.length > 0" id="shipper_id" [(ngModel)]="shipper_id" #name="ngModel" name="shipper_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombos" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>
        <div class="col-lg-4 mb-3" *ngIf="isSuperadmin || isAdmin">
          <label class="text-black font-w600">Vendor</label>
            <select class="form-control default-select" *ngIf="vcomboss.length > 0" id="vendor_id" [(ngModel)]="vendor_id" #name="ngModel" name="vendor_id">
              <option value="">Please select</option>
              <option *ngFor="let n of vcomboss" value="{{n.id}}">{{n.name}}</option>
            </select>
        </div>

				<div class="col-lg-12">
					<div class="form-group mb-0">
            <!-- <button (click)="searchTitle()" class="submit btn btn-primary">Search</button> -->
            <button (click)="searchTitle()" class="submit btn btn-primary mb-3 mr-3">Search</button>
            <button (click)="downloadExcel()" class="submit btn btn-danger mb-3 mr-3"><i class="fa fa-file-excel-o mr-2"></i> Export Excel</button>
					</div>
				</div>
			</div>
		<!-- </form> -->
	</div>
</ng-template>
<!-- Modal ADD -->
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
