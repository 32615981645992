<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <!-- <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Menu</a></li>
                  </ol>
              </div>
          </div>
      </div> -->

      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <input
            type="text"
            class="form-control"
            placeholder="Search by title"
            [(ngModel)]="title"
          />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div>
        </div>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
    </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List Menu</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Title</th>
                      <th scope="col">Url</th>
                      <!-- <th scope="col">Icon</th> -->
                      <!-- <th scope="col">Main Menu</th> -->
                      <th scope="col">Aktif</th>
                      <th scope="col">No Urut</th>
                      <!-- <th scope="col">Password</th> -->
                      <!-- <th scope="col">Level</th> -->
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <td>
                          {{ tutorial.title }}
                        </td>
                        <td>
                          {{ tutorial.url }}
                        </td>
                        <!-- <td>
                          {{ tutorial.icon }}
                        </td> -->
                        <!-- <td>{{ tutorial.is_main_menu }}</td> -->
                        <td>{{ tutorial.is_aktif }}</td>
                        <td>{{ tutorial.no_urut }}</td>
                        <td>
                            <button type="button" class="btn btn-info btn-xxs mr-2 mb-2" (click)="editUser(sendMessageModal, tutorial, 'update')" placement="left" ngbTooltip="Edit">
                              <i class="fa fa-pencil color-info"></i>
                              </button>
                            <button type="button" class="btn btn-danger btn-xxs mr-2 mb-2" (click)="deleteMenu(tutorial)" placement="left" ngbTooltip="Delete">
                              <i class="fa fa-trash color-info"></i>
                              </button>
                        </td>
                      </tr>
                    </tbody>
                </table>
               </div>

              <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize" (pageChange)="handlePageChange($event)">
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize" (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>

              </div>
            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Menu</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Title <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="title"
              required
              [(ngModel)]="fadd_role.title"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="title"
            />
          </div>
				</div>
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Url <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="url"
              required
              [(ngModel)]="fadd_role.url"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="url"
            />
          </div>
				</div>
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Icon <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="icon"
              required
              [(ngModel)]="fadd_role.icon"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="icon"
            />
          </div>
				</div>
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Main Menu <span class="required">*</span></label>
            <select class="form-control default-select" *ngIf="vcombo.length > 0" id="is_main_menu" [(ngModel)]="fadd_role.is_main_menu" #name="ngModel" name="is_main_menu">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombo" value="{{n.id}}">{{n.title}}</option>
            </select>
          </div>
				</div>

        <div class="col-lg-12 mb-3">
          <label class="text-black font-w600">Status <span class="required">*</span></label>
              <select class="form-control default-select" id="is_aktif" [(ngModel)]="fadd_role.is_aktif" #name="ngModel" name="is_aktif">
                <option ng-value="">Please select</option>
                <option value="Y">Active</option>
                <option value="N">Non Active</option>
              </select>
        </div>

        <div class="col-lg-6">
					<div class="form-group">
						<label class="text-black font-w600">No Urut <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="no_urut"
              required
              [(ngModel)]="fadd_role.no_urut"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="no_urut"
            />
          </div>
				</div>
				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
