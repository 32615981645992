<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <!-- <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
            <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Kurir</a></li>
          </ol>
        </div>

      </div>


    </div> -->

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        <input type="text" class="form-control" placeholder="Search by title" [(ngModel)]="title" />
        <div class="input-group-append">
          <button class="btn" type="button" (click)="searchTitle()">
            <i class="flaticon-381-search-2"></i>
          </button>
        </div>
      </div>
      <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
      <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List Kurir</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <!-- <th scope="col">First Code Resi</th> -->
                    <th scope="col">Code</th>
                    <th scope="col">Name</th>
                    <!-- <th scope="col">Phone</th> -->
                    <!-- <th scope="col">Address</th> -->
                    <!-- <th scope="col">Template</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <th scope="row">
                      {{ x+1 }}
                    </th>
                    <!-- <td>
                      {{ tutorial.code_resi }}
                    </td> -->
                    <td>
                      {{ tutorial.code }}
                    </td>
                    <td>{{ tutorial.name }}</td>
                    <!-- <td>{{ tutorial.phone_number }}</td> -->
                    <!-- <td>{{ tutorial.address }}</td> -->
                    <!-- <td>{{ tutorial.template_option }}</td> -->
                    <td>
                      <!-- <button type="button" class="btn btn-rounded btn-success" (click)="editUser(sendMessageModal, tutorial, 'view')"><span
                            class="btn-icon-left text-success"><i class="fa fa-eye color-success"></i>
                            </span>Detail</button>&nbsp; -->

                      <button type="button" class="btn btn-rounded btn-info"
                        (click)="editUser(sendMessageModal, tutorial, 'update')"><span
                          class="btn-icon-left text-info"><i class="fa fa-pencil color-info"></i>
                        </span>Edit</button>&nbsp;
                        <button type="button" class="btn btn-rounded btn-danger" (click)="deleteShippers(tutorial)"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                          </span>Remove</button>&nbsp;
                          <!-- <button type="button" class="btn btn-rounded btn-dark" [routerLink]="['/admin/app-mst-shippersprice/', tutorial.id ]"><span
                            class="btn-icon-left text-dark"><i class="fa fa-tags color-dark"></i>
                            </span>Price List</button> -->
                      <!-- <button type="button" class="btn btn-rounded btn-danger"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                          </span>Remove</button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)">
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize"
                  (change)="handlePageSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form Kurir</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- START FORM -->
    <div class="form-validation">
      <form class="form-valide" action="#" method="post" [formGroup]="angForm" novalidate>
        <div class="row">
          <div class="col-xl-12">

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="code">Code
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="code" formControlName="code" placeholder="Enter a code.." [(ngModel)]="code"
                >
                <div
                  *ngIf="angForm.controls['code'].invalid && (angForm.controls['code'].dirty || angForm.controls['code'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['code'].errors.required">
                    Code is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="name">Name
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="name" formControlName="name"
                  placeholder="Enter a name.." [(ngModel)]="name">
                <div
                  *ngIf="angForm.controls['name'].invalid && (angForm.controls['name'].dirty || angForm.controls['name'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['name'].errors.required">
                    Name is required.
                  </div>
                </div>
              </div>
            </div>




            <div class="form-group row">
              <div class="col-lg-8 ml-auto">
               <button (click)="saveTutorial()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- END FORM -->
  </div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
