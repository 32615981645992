<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
      <!-- Add Order -->
      <!-- <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
              <div class="welcome-text">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
                      <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Tracking</a></li>
                  </ol>
              </div>

          </div>


      </div> -->

      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <input
            type="text"
            class="form-control"
            placeholder="Search by No Resi"
            [(ngModel)]="title"
          />
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              (click)="searchTitle()"
            >
            <i class="flaticon-381-search-2"></i>
            </button>
          </div>
        </div>
        <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
        <!-- <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a> -->
   </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
                <h4 class="card-title">List Tracking</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">No Resi</th>
                      <th scope="col">Status</th>
                      <th scope="col">Tanggal</th>
                      <!-- <th scope="col">Action</th> -->
                    </tr>
                  </thead>
                    <tbody>
                      <tr *ngFor="let tutorial of tutorials; index as x">
                        <th scope="row">
                          {{ x+1 }}
                        </th>
                        <td>
                          {{ tutorial.no_resi }}
                        </td>
                        <td>
                          {{ tutorial.status }}
                        </td>
                        <td>
                          {{ tutorial.createdAt | date : 'd MMM y HH:mm:ss' }}
                        </td>
                        
                        <!-- <td>{{ tutorial.description }}</td> -->
                        <!-- <td>{{ tutorial.note }}</td> -->
                        <!-- <td> -->
                          <!-- <button type="button" class="btn btn-rounded btn-info" (click)="editUser(sendMessageModal, tutorial, 'update')"><span
                            class="btn-icon-left text-info"><i class="fa fa-pencil color-info"></i>
                            </span>Edit</button>&nbsp; -->
                            <!-- <button type="button" class="btn btn-rounded btn-danger" (click)="deleteTrackings(tutorial)"><span
                              class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                              </span>Remove</button> -->
                        <!-- </td> -->
                      </tr>
                    </tbody>
                </table>
               </div>

               <div class="d-flex flex-wrap justify-content-between p-2">
                <ngb-pagination class="pagination-responsive"
                  [collectionSize]="count"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  (pageChange)="handlePageChange($event)" [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                >
                </ngb-pagination>
                <div class="form-group">
                  <label class="mr-sm-2">Show:</label>
                  <select
                    class="mr-sm-2 custom-select"
                    style="width: auto"
                    name="pageSize"
                    (change)="handlePageSizeChange($event)"
                  >
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                  entries
                </div>
              </div>

            </div>
        </div>
        </div>

      </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Role</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form class="comment-form">
			<div class="row">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Connote <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="connote"
              required
              [(ngModel)]="fadd_role.connote"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="connote"
            />
          </div>
				</div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Airwaybill <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="airwaybill"
              required
              [(ngModel)]="fadd_role.airwaybill"
              #name="ngModel"
              [disabled]="fadd_role.action == 'view'"
              name="airwaybill"
            />
          </div>
				</div>
        <div class="col-lg-12">
          <label class="text-black font-w600">Status <span class="required">*</span></label>
              <select class="form-control default-select" id="status" [(ngModel)]="fadd_role.status" #name="ngModel" name="status">
                <option ng-value="">Please select</option>
                <option ng-value="MANIFESTED">MANIFESTED</option>
                <option ng-value="CLEREANCE AT ORIGIN">CLEREANCE AT ORIGIN</option>
                <option ng-value="TRANSIT AT ORIGIN">TRANSIT AT ORIGIN</option>
                <option ng-value="ARRIVED AT DESTINATION">ARRIVED AT DESTINATION</option>
                <option ng-value="TRANSIT AT DESTINATION">TRANSIT AT DESTINATION</option>
                <option ng-value="OUT FOR DELIVERY">OUT FOR DELIVERY</option>
                <option ng-value="TROUBLE">TROUBLE</option>
                <option ng-value="DELIVERED">DELIVERED</option>
              </select>
            <!-- <select class="form-control default-select" *ngIf="vcombo.length > 0" id="status" [(ngModel)]="fadd_role.status" #name="ngModel" name="status">
              <option value="">Please select</option>
              <option *ngFor="let n of vcombo" value="{{n.id}}">{{n.name}}</option>
            </select> -->
        </div>
        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Description <span class="required">*</span></label>
            <textarea class="form-control" class="form-control"
            required
            [(ngModel)]="fadd_role.description"
            #name="ngModel"
            [disabled]="fadd_role.action == 'view'"
            name="description"></textarea>
          </div>
				</div>
        <!-- <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Note <span class="required">*</span></label>
            <textarea class="form-control" class="form-control"
            required
            [(ngModel)]="fadd_role.note"
            #name="ngModel"
            [disabled]="fadd_role.action == 'view'"
            name="note"></textarea>
          </div>
				</div> -->
				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="saveTutorial()" [disabled]="name.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
